import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { css } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { parseQueryTags, assignQueryValue, getTagsFromSessionStorage } from '@lib/searchQuery';
import { getPageElements } from '@lib/utils';
import Page from '@components/Page';
import { mapKeys } from 'lodash';

export default function CalendarTemplate({
  data: {
    calendarBG,
    page
  },
  pageContext: {
    language,
    refs,
    meta
  },
  location
}) {
  const { title, thanks } = getPageElements(page);
  const { firstname, lastname, ...tags } = parseQueryTags(location, ['email', 'firstname', 'lastname', 'company']);
  const metaTags = getTagsFromSessionStorage();
  const metaData = mapKeys(metaTags, key => `metadata[${key}]`);
  const name = (firstname || lastname) ? { name: `${firstname || ''}${firstname && lastname ? ' ' : ''}${lastname || ''}` } : {};
  const prefill = new URLSearchParams(Object.assign(name, tags, metaData));
  return (
    <Page>
      <Helmet>
        <style type="text/css">
          {`
            html, body, #___gatsby, #gatsby-focus-wrapper {
              height: 100%;
              background-color: #7bc46b;
            }
          `}
        </style>
      </Helmet>
      <BackgroundImage fluid={calendarBG.sharp.fluid} className="h-100" >
        <div className="d-flex justify-content-center w-100 h-100" >
          <div className="pt-5 pb-3">
            {firstname && (
              <h2 className="text-center text-white" css={css` font-weight: 200; font-size: 2rem; `} >
                {`${thanks} ${firstname}!`}
              </h2>
            )}
            <h1 className="text-center text-white text-uppercase" css={css` font-weight: 200; font-size: 2.5rem; `} >
              {title}
            </h1>
            <div className="container">
              <div className="d-flex justify-content-center align-items-center">
                <div className="embed-responsive embed-responsive-16by9 rounded shadow-sm" style={{ minHeight: '800px' }}>
                  <iframe className="embed-responsive-item" scrolling="no" src={`https://calendar.nospoilers.ai/team/demo/no-spoilers-demo${prefill ? `?${prefill}` : ''}`} frameBorder="0" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    calendarBG: file(relativePath: { eq: "pricing-bg.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;